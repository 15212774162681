// nav element detection
gumshoe.init({
	selector: '.topnav a', // Default link selector (must use a valid CSS selector)
	selectorHeader: '.topnav', // Fixed header selector (must use a valid CSS selector)
});

// animation on scroll
// AOS.init();

// nav scroll to section
$(".nav__item--anchor").on("click", function(e){
	e.preventDefault();
	var ele = $(this).attr("href");
	var $ele = $(ele);
	// var classname = ele.substr(1);
	$("html, body")
		.animate(
			{ scrollTop: $ele.prop("offsetTop") },
			1 * 1000
		);
})


$("form").submit(function(e){
	e.preventDefault();
	// remove any messages
	$(this).find(".message").remove()
	// prep + send data
	var form_data = $(this).serialize();
	var processor = $(this).attr("action");
	$.get( processor, form_data, function( data ) {
		data = JSON.parse(data);
		var $form = $("form");
		var output;
		if (data.errors) {
			output = "Error: " + data.errors.join();
			output = $("<div/>").addClass("message error").text(output);
		}
		else {
			$form[0].reset();
			output = "Your message has been sent! Thank you!";
			output = $("<div/>").addClass("message success").text(output);
		}
		$form.append(output);
	});
	// console.log( form_data  );
})
